import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4845519e = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _341df4f4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _df57d132 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _5d7be470 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _2b279539 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _098a32f2 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _7f7ee4c7 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _0542b2b4 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _119ab0ba = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _7096b1da = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _7ca77b22 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _f1a97dfc = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _59fbba7e = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _75b82d04 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _254f47f6 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _a74a4594 = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _102a49cf = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8ee1dd1c = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _84dd8b16 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _3f3e9ca1 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _986554e2 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _8677b8b4 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _313cb2e9 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _72403787 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _32112fa8 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _6594cdb6 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _0405fddc = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _076d0a30 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2e294e64 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _59767628 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _cbd1fa68 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _87675f98 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _3475d760 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _42d970c9 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _21737c9d = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _029e1c36 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _7899ee5c = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _7b838cc6 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _d71b6cb2 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _b3b3d168 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _6a4936d0 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _995c2034 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _13e717e2 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _084ade1f = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _20e9f629 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _1f7cf94f = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _7899ebe7 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _02473354 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cbd0f53a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4845519e,
    children: [{
      path: "",
      component: _341df4f4,
      name: "account___nl"
    }, {
      path: "details",
      component: _df57d132,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _5d7be470,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _2b279539,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _098a32f2,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _7f7ee4c7,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _0542b2b4,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _119ab0ba,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _7096b1da,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _7ca77b22,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _f1a97dfc,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _59fbba7e,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _75b82d04,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _254f47f6,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _a74a4594,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _102a49cf,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _8ee1dd1c,
    children: [{
      path: "",
      component: _84dd8b16,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _3f3e9ca1,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _986554e2,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _8677b8b4,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _313cb2e9,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _72403787,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _32112fa8,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _6594cdb6,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _0405fddc,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _076d0a30,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _2e294e64,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _59767628,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _cbd1fa68,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _87675f98,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _3475d760,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _42d970c9,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _21737c9d,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _029e1c36,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _7899ee5c,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _7b838cc6,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _d71b6cb2,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _b3b3d168,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _6a4936d0,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _995c2034,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _13e717e2,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _084ade1f,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _20e9f629,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _1f7cf94f,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _7899ebe7,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _02473354,
    name: "index___nl"
  }, {
    path: "/*",
    component: _cbd0f53a,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
